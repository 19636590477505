import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'


import SearchResults from '../components/SearchResults'

export default function SearchPage() {

  const data = useStaticQuery(graphql`{
    allWpMenu {
      ...getMenus
    }
    wpPage {
      language {
        locale
      }
      translations {
        slug
      }
    }
    allWpPost {
      nodes {
        id
        title
        link
        slug
        nodeType
        language {
          locale
        }
        featuredImage {
          node {
            file: localFile {
              image: childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWpPage {
      nodes {
        id
        title
        content
        link
        nodeType
        language {
          locale
        }
      }
    }
    allWpDiscount {
      nodes {
        id
        title
        content
        nodeType
        language {
          locale
        }
        featuredImage {
          node {
            file: localFile {
              image: childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWpOrganization {
      nodes {
        id
        title
        link
        nodeType
        content
        language {
          locale
        }
      }
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
      }
    }
  }`)
  const language = {
    locale: 'en_US'
  }

  const translations = [{
    slug: 'leit'
  }]
  return (
    <SearchResults data={data} language={language} translations={translations} />
  )
}